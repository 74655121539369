import { IEnvironment } from '@env/enviroment.interface';

export const environment: IEnvironment = {
  NAME: 'staging',
  production: true,

  baseUrl: 'https://staging.awarenow.io',
  signalHost: 'https://signal-staging.awarenow.io',
  chatHost: 'https://messenger-staging.awarenow.io',
  notificationsHost: 'https://notif-staging.awarenow.io',

  apiHost: 'https://api-staging.awarenow.io',
  sentryKey: 'https://9cf7806dccf7437e83671d8c7ce96895@o651554.ingest.sentry.io/5762883',

  disqusId: null, // 'awarenow-staging',

  bookWidgetBaseHref: '/book',

  guidesRoute: 'coaches',
  guideRoute: 'coach',

  dateTimeLocale: 'en-US',

  membershipRoute: 'subscription',

  enableLinksInMainHeader: true,
  publicEventsRoute: 'public-events',

  /**
   * @description Sets the time format
   * @type {string}
   * @see https://angular.io/api/common/DatePipe#pre-defined-format-options
   */
  timeFormat: 'h:mma',

  featureFlagServiceId: '62601ffbebd086158b99d9cb',

  platformStyle: {
    DAY: 'd',
    WEEK_DAY: 'EEEE',
    MONTH: 'MMM',
    YEAR: 'yyyy',
    TIME: 'HH:mm',
    HUMANIZE_MILLISECONDS: 24 * 60 * 60 * 1000,
    DEFAULT: 'yyyy-MM-dd',

    // custom for 'en-US'
    TIME_WITH_MINUTES: 'h:mma',
    TIME_WITHOUT_MINUTES: 'ha'
  }
};
